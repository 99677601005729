import React from "react"
import loadable from "@loadable/component"
import { graphql } from "gatsby"

const Layout = loadable(() => import("@common/Layout/Layout"))
const SEO = loadable(() => import("@components/Common/SEO/SEO"))
const RealEstate = loadable(() => import("@components/RealEstate"))

const RealEstatePage = ({ path, data }) => {
  return (
    <>
      <SEO
        title="Tailored Real Estate Software Development Services | Custom Solutions for Property Industry"
        titleTemplate="Impressit"
        SEOData={data.allStrapiSeoDescriptions?.nodes}
        description="Enhance real estate with custom software. Tailored solutions for owners, agencies, brokers, and tenants. Embrace digital innovation with Impressit"
        isNotIndexed={false}
        ownMeta={{
            name: "googlebot",
            content: "all"
        }}
        path={path}
      />
      <Layout path={path} isNoPadding>
        <RealEstate />
      </Layout>
    </>
  )
}
export default RealEstatePage

export const realEstatePageQuery = graphql`
    query realEstatePageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
